export const config = {
    site_name: 'Marketis',
        // base_url: "https://apimarket.devtop.online",

    // media_path: 'http://localhost:5000',
    // base_url: 'http://localhost:4200',
    
        media_path: 'https://apimarket.devtop.online',
    base_url: 'https://marketplace.devtop.online',
    google_client_id: '619071255969-ia60tv3pedgk0lha8h0mt97quevu3dsb.apps.googleusercontent.com',
    facebook_client_id: '3021698738051232',
    // blockchain:"Ropsten",
    // blockchain_url: "https://ropsten.etherscan.io/",
    // blockchain: 'BSC-Mainnet',
    // blockchain_url: 'https://bscscan.com/',
    blockchain:"Polygon Testnet",
    blockchain_url: "https://mumbai.polygonscan.com/",
    is_demo: false
};
